import React from "react";

class surf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "none"
    };
  }
  componentDidMount() {
    this.setState({ display: "block" });
  }

  render() {
    return (
      <>
      <section style={{ display: this.state.display }}>
        <div className="install-steps">
          <div className="step-item">
            <div className="step-number">1</div>
            <div className="step-text">Click <strong>"Continue"</strong></div>
          </div>
          <div className="step-item">
            <div className="step-number">2</div>
            <div className="step-text">Run Installer</div>
          </div>
          <div className="step-item">
            <div className="step-number">3</div>
            <div className="step-text">Accept and Install</div>
          </div>
          <svg className="steps-arrow" viewBox="0 0 24 24">
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/>
          </svg>
        </div>
        {this.props.children}
      </section>
      </>
    );
  }
}

export default surf;
