import React, { useEffect } from "react"
import Data from "./data/instdata.json"
import Styles from "./css/installsteps.module.scss"
import {isChrome,isFirefox,isIeEdge,getBrowser} from "@tightrope/lpscripts/browserdetect"
import FooterLinksSingle from "@tightrope/footerlinks/footerlinks_single.js"

class installInstructions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      display: 'none',
      pageData: props.data,
      data: Data,
      showStep1: true,
      fadeOut: false,
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
    if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
    if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
    if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
    if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    // Add the Lottie script to the document head
    const script = document.createElement('script');
    script.src = "https://unpkg.com/@dotlottie/player-component@2.7.12/dist/dotlottie-player.mjs";
    script.type = "module";
    document.head.appendChild(script);

    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }

    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});

      this.state.isChrome = isChrome();
      this.state.isFirefox = isFirefox();
      this.state.isIeEdge = isIeEdge();

      if(isChrome()){
        const browserData = Object.assign(this.state.data, this.state.data.chrome);
        this.setState({data: browserData});
      }
      if(isFirefox()){
        const browserData = Object.assign(this.state.data, this.state.data.firefox);
        this.setState({data: browserData});
      }
      if (typeof window != "undefined") {
        window.mapParams();
      }
    });

    // Add timer for step transition
    setTimeout(() => {
      this.setState({ fadeOut: true });
      setTimeout(() => {
        this.setState({
          showStep1: false,
          fadeOut: false
        });
      }, 1000); // Wait for fade out animation to complete
    }, 6000);
  }

  render() {
    return (
      <div className={Styles.installSteps} style={{ display: this.state.display }}>
        <div className={Styles.pageContainer}>
          <div className={Styles.contentContainer}>
            <ul>
              {this.state.showStep1 ? (
                <li id={Styles.step1} className={this.state.fadeOut ? Styles.fadeOut : ''}>
                  <div className={Styles.modal}>
                    <img src={this.state.data.logo} alt="carbonate browser logo" className={Styles.logo}/>
                      <span>
                        <h1>{this.state.data.header}</h1>
                        <div>
                          <dotlottie-player
                            src="https://lottie.host/1e97f0a0-56e7-481a-80c8-00e1a92956cb/NedAKpq8kv.json"
                            background="transparent"
                            speed="1"
                            style={{ width: '300px', height: '300px' }}
                            loop
                            autoplay
                          ></dotlottie-player>
                          <p className={Styles.downloadText}>{this.state.data.downloadText}</p>
                        </div>
                      </span>
                  </div>
                  <p className={Styles.retryText}>{this.state.data.retryText}
                    <a href="#" onClick={() => window.triggerInstall()}>{this.state.data.retryLink}</a>
                  </p>
                </li>
              ) : (
                <li id={Styles.step2} className={Styles.fadeIn}>
                  <div className={Styles.steps}>
                    <img src={this.state.data.logo} alt="carbonate browser logo" className={Styles.logo}/>

                      <h1>{this.state.data.header}</h1>
                      <div className={Styles.stepsGrid}>
                        <div className={Styles.stepCard}>
                          <div className={Styles.stepNumber}>1</div>
                          <p className={Styles.stepText}>{this.state.data.step1}</p>
                          <div className={Styles.imageContainer}>
                            <img src={this.state.data.first} alt="first step" className={Styles.stepImage} />
                          </div>
                        </div>
                        <div className={Styles.stepCard}>
                          <div className={Styles.stepNumber}>2</div>
                          <p className={Styles.stepText}>{this.state.data.step2}</p>
                          <div className={Styles.imageContainer}>
                            <img src={this.state.data.second} alt="second step" className={Styles.stepImage} />
                          </div>
                        </div>
                        <div className={Styles.stepCard}>
                          <div className={Styles.stepNumber}>3</div>
                          <p className={Styles.stepText}>{this.state.data.step3}</p>
                          <div className={Styles.imageContainer}>
                            <img src={this.state.data.third} alt="third step" className={Styles.stepImage}/>
                          </div>
                        </div>
                      </div>

                    <p className={Styles.retryText}>{this.state.data.retryText}
                    <a href="#" onClick={() => window.triggerInstall()}>{this.state.data.retryLink}</a>
                  </p>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className={Styles.footerlinksContainer}>
            <div className={Styles.footerlinks}>
              <a href="https://www.carbonatebrowser.com/privacy-policy/" target="_blank">Privacy Policy</a>
              <a href="https://www.carbonatebrowser.com/terms-of-service/" target="_blank">Terms of Service</a>
              <a href="https://www.carbonatebrowser.com/contact-us/" target="_blank">Contact Us</a>
              <a href="https://www.carbonatebrowser.com/unsubscribe/" target="_blank">Unsubscribe</a>
              <a href="https://www.carbonatebrowser.com/uninstall/" target="_blank">Uninstall</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default installInstructions
