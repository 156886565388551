import React, { useEffect } from "react";
import Helmet from "react-helmet";
import HomepageLayout from "src/layouts/homepage";
import JSONData from "branding/site-metadata.json";
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

export default function Privacy() {
    const footerStyles = {
        footer: {
            fontFamily: "'Roboto', sans-serif !important",
            display: "block",
            position: "relative",
            color: "#333",
            padding: 0,
            width: "100%",
            margin: 0
        },
        ul: {
            position: "relative",
            listStyle: "none",
            margin: 0,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            textAlign: "center",
            color: "#333",
            fontSize: "12px",
            padding: "12px 0"
        },
        li: {
            padding: "0 4px 0 0",
            position: "relative",
            "&:after": {
                content: "'|'",
                padding: "0 0 0 4px"
            },
            "&:last-child": {
                padding: 0,
                "&:after": {
                    content: "none",
                    padding: 0
                }
            },
            "&:nth-child(6):after": {
                content: "none",
                padding: 0
            }
        },
        a: {
            color: "#333"
        },
        "@media only screen and (max-width: 768px)": {
            footer: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#333",
                width: "100%",
                right: "auto",
                bottom: "auto"
            },
            ul: {
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <HomepageLayout>
            <Helmet data={JSONData}>
                <title>Privacy Policy</title>
                <link 
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap" 
                    rel="stylesheet" 
                />
            </Helmet>
            <header style={{ background: "#f0f0f0", width: "100%", padding: "20px", textAlign: "center" }}>
                <img src="/img/carbonate-logo.png" alt="Main Logo" style={{ width: "180px" }}/>
            </header>
            <div
                name="termly-embed"
                data-id="bb2f7ceb-536e-4bfd-afd2-1156bf320579"
                data-type="iframe"
            ></div>
            <footer style={footerStyles.footer}>
                <RawFooter />
            </footer>
        </HomepageLayout>
    );
}
