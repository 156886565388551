import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import InstallSteps from "src/components/installSteps"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const installData = import("./data/data.json");

export default function directchroma() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       #chromelpsteps-module--chromelp .chromelpsteps-module--main {
        height: 100vh;
       }

    `}
    </style>
    <style type="text/css">
         </style>
    <title>Get Curated Content and Apps - Carbonate</title></Helmet>
    <section>
      <InstallSteps data={installData}></InstallSteps>
     
      </section>
    </HomepageLayout>
  )
}
