import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmaWave2 from "src/components/nonbmaWave2"
import Surf from "src/components/surf"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const wavelpData2 = import("./data/data.json");

export default function prdbest() {
  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
      body {
        font-family: 'Roboto', sans-serif;
      }
      .footerlinks-module--br {
        display: none!important;
      }

      .btf_cb-module--btfCb button.btf_cb-module--install {
        background: #39b54a;
        padding: 20px;
        font-size: 30px;
        border-radius: 32px;
        box-shadow: 0 10px 20px -10px #23e332;
        margin-bottom: 25px;
      }

      #ctadisclosurecb3-module--ctadisclaimer {
        width: 72%;
        margin: 0 auto;
        padding-left: 540px;
        padding-right: 75px;
        }

      .carbDisclosure {
        padding-left: 250px;
      }

      #carbonate_disc_search {
        padding-left: 250px;
      }

      .wave-module--wave button.wave-module--waveBtn{
        animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
        animation-fill-mode: forwards;
        position: relative;
left: 250px;
    margin: 75px auto 32px;
      }

      @keyframes slideInFromLeft {
        from {
          background-position: right bottom;
        }
        to {
          background-position: 0 0;
          background-color: #096bfb;
        }
      }

      .wave-module--wave h1 {
        font-weight: normal;
        font-size: 1rem;
        width: 640px;
        margin-bottom: 2rem;
        margin: 0rem auto 2rem;
      }

      .wave-module--wave ul.wave-module--bullets {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 640px;
        margin: 0 auto;
        padding: 0;
        list-style: none;
      }

      .wave-module--wave ul.wave-module--bullets li {
        text-align: left;
        margin: 0;
        padding-left: 24px;
        position: relative;
        font-size: .85rem;
      }

      .wave-module--wave ul.wave-module--bullets li:before {
        content: "•";
        position: absolute;
        left: 8px;
        color: #333;
      }

      .install-steps {
        width: 225px;
        position: absolute;
        top: 365px;
        left: 31%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .step-item {
        display: flex;
        align-items: center;
        gap: 20px;
      }

      .step-number {
        width: 48px;
        height: 48px;
        background: #e8f3ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #666;
      }

      .step-text {
        font-size: 18px;
        color: #333;
      }

      .step-text strong {
        color: #000;
      }

      .steps-arrow {
        position: absolute;
        right: -80px;
        top: 50%;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        fill: #39b54a;
      }

      @keyframes pulseSize {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      .step-item:first-child .step-number {
        animation: pulseSize 2s ease-in-out infinite;
        background: #39b54a;
        color: white;
      }

      .wave-module--wave .wave-module--center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 75px 0;
      }

      .wave-module--wave .wave-module--atfGrid {
      height: auto;
      }
    `}
    </style>
    <style type="text/css">
    </style>
    <title>View any web page easily - Easy View</title></Helmet>
    <Surf><NonbmaWave2 data={wavelpData2}></NonbmaWave2></Surf>
    </HomepageLayout>
  )
}
